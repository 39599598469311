@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-color: #6b7280 transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111827;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.customScrollbar {
  /* -ms-overflow-style: none; */
  /* scrollbar-width: none; */
  scrollbar-color: #6b7280 transparent;
}

.customScrollbar::-webkit-scrollbar {
  background: #6b7280;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}

.animate-fadeOut {
  @apply opacity-0;
}
